import React from 'react';
import {graphql} from 'gatsby';
import {pick} from "underscore";
import {connect} from "react-redux";
import matches from 'validator/lib/matches';
import isIP from 'validator/lib/isIP';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import isPort from 'validator/lib/isPort';
import tw, {styled, GlobalStyles} from 'twin.macro';
import Helmet from "react-helmet";

import {iState} from "@/state";
import Theme from "@/components/theme";
import Regions from "@/components/create-instance/regions";
import AuthDigitalocean from "@/components/auth/digitalocean";
import {
	persist,
	addError,
	setName,
	setEmail,
	setRegion,
	setProvider,
	setProtocol,
	setPort,
	setMtu,
	setDnsServers,
	setMaskIp,
	setService,
	clearErrors,
	iState as iStateForm
} from "@/state/reducers/form";
import {iState as iStateDigitalocean, reqAuth as reqDigitaloceanAuth} from "@/state/reducers/digitalocean";
import Seo from "@/components/seo";


const Wrapper = styled.div(
	() => [
		tw`sm:max-w-full
		p-4
		md:p-12
		lg:px-0
		lg:max-w-screen-lg
		m-auto`
	]
);

const Error = styled.p(
	() => [
		tw`text-red-500 text-sm`
	]
);

const Description = styled.p(
	() => [
		tw`text-gray-400 font-normal mt-2`
	]
);

interface iCreateButton {
	disabled: boolean;
}

const CreateButton = styled.button<iCreateButton>(
	({disabled}) => [
		tw`mb-5
		w-full
		flex
		justify-center
		py-4
		px-4
		border
		border-transparent
		text-xl
		font-bold
		rounded-md
		text-white
		bg-primary-500
		hover:bg-primary-400
		focus:outline-none
		focus:border-primary-500
		focus:shadow-outline-blue
		active:bg-primary-500
		transition
		duration-150
		ease-in-out`,
		disabled && tw`opacity-50 cursor-not-allowed`
	]
);

const AuthButton = styled(CreateButton)(
	() => [
		tw`mt-2
		text-sm
		font-normal
		py-2
		px-2
		`
	]
);

export interface Props {
	data: any;
	path: string;

	form: iStateForm;
	digitalocean: iStateDigitalocean;

	reqDigitaloceanAuth: typeof reqDigitaloceanAuth;
	persist: typeof persist;
	setMtu: typeof setMtu;
	setName: typeof setName;
	setEmail: typeof setEmail;
	setRegion: typeof setRegion;
	setProvider: typeof setProvider;
	setProtocol: typeof setProtocol;
	setPort: typeof setPort;
	setDnsServers: typeof setDnsServers;
	setMaskIp: typeof setMaskIp;
	setService: typeof setService;
	addError: typeof addError;
	clearErrors: typeof clearErrors;
}

const Create: React.FunctionComponent<Props> = (props: Props) => {
	let serviceConfig = {
		name       : "OpenVPN",
		serviceName: "openvpn",
	};

	if (location.pathname.includes("create/wireguard")) {
		serviceConfig = {
			name       : "Wireguard",
			serviceName: "wireguard",
		};
	}

	React.useEffect(() => {
		props.setService(`${serviceConfig.serviceName}`);
	}, []);

	React.useEffect(() => {
		if (props.form.provider != "") {
			props.setProvider(props.form.provider);
		}
	}, []);

	const hasValidDoAuth = (): boolean => {
		return props.digitalocean.authToken?.token != "" &&
			props.digitalocean.authToken?.expiresAt != undefined &&
			props.digitalocean.authToken?.expiresAt > Math.floor(Date.now() / 1000);
	};

	const validate = () => {
		let hasErrors = false;
		props.clearErrors();

		if (!isEmail(props.form.email)) {
			hasErrors = true;
			props.addError("email", "Invalid email address");
		}

		if (!matches(props.form.name, "^([a-zA-Z_-])*[^\\s]\\1*$", "i")) {
			hasErrors = true;
			props.addError("name", "Alpha-numeric characters with dashes and underscores only");
		}

		if (!isIP(props.form.dnsServers[0])) {
			hasErrors = true;
			props.addError("dnsServers", "Invalid dns server");
		}

		if (!isIP(props.form.dnsServers[1])) {
			hasErrors = true;
			props.addError("dnsServers", "Invalid dns servers");
		}

		if (!isPort(props.form.port)) {
			hasErrors = true;
			props.addError("port", "Invalid port");
		}

		if (isEmpty(props.form.region || "")) {
			hasErrors = true;
			props.addError("region", "Please select a location");
		}

		if (!hasErrors && canContinue()) {
			props.persist();
		}
	};

	const canContinue = (): boolean => {
		return !(props.form.provider == "digitalocean" && !hasValidDoAuth());
	};

	return (
		<Theme>
			<GlobalStyles/>
			<Helmet bodyAttributes={{class: 'font-body'}}/>
			<Seo {...pick(props, "data", "path")}/>

			<Wrapper>
				<div className="pb-5 border-b border-gray-200">
					<div className="-ml-2 -mt-2 flex flex-wrap items-baseline">
						<h1 className="ml-2 mt-2 text-2xl leading-6 font-extrabold text-primary-background">
							Create {serviceConfig.name} instance
						</h1>
					</div>
				</div>

				<div className="py-12">
					<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-b sm:border-gray-200 sm:pb-6">
						<label htmlFor="provider"
							  className="block text-sm font-bold leading-5 text-primary-background sm:mt-px self-center">
							Your email address

							<Description>
								We will use it to send you a notification once the server is up. Just
								in case you accidentally close this page or something else happens.
							</Description>
						</label>
						<div className="mt-1 sm:mt-0 sm:col-span-2">
							<input
								type="email"
								name="email"
								className="form-input mt-1 block w-full"
								value={props.form.email}
								onChange={event => props.setEmail(event.target.value)}
							/>
							{props.form.errors?.email && (
								<Error>{props.form.errors.email}</Error>
							)}
						</div>
					</div>

					<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-b sm:border-gray-200 sm:pb-6 mt-5">
						<label htmlFor="provider"
							  className="block text-sm font-bold leading-5 text-primary-background sm:mt-px self-center">
							Server name

							<Description>
								Pick a name that you can recognize. The droplet will be named after
								your input here. Alpha-numeric characters, dashes and underscores only please.
							</Description>
						</label>
						<div className="mt-1 sm:mt-0 sm:col-span-2">
							<input
								type="text"
								className="form-input mt-1 block w-full"
								value={props.form.name}
								onChange={event => props.setName(event.target.value)}
							/>

							{props.form.errors?.name && (
								<Error>{props.form.errors.name}</Error>
							)}
						</div>
					</div>

					<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-b sm:border-gray-200 sm:pb-6 mt-5">
						<label htmlFor="provider"
							  className="block text-sm font-bold leading-5 text-primary-background sm:mt-px self-center">
							Provider

							<Description>
								Currently only DigitalOcean is supported. More providers coming soon.
							</Description>
						</label>
						<div className="mt-1 sm:mt-0 sm:col-span-2">
							<div className="flex rounded-md shadow-sm w-full">
								<label className="block w-full">
									<select
										value={props.form.provider}
										onChange={event => props.setProvider(event.target.value)}
										className="form-select block w-full mt-1"
										name={"provider"}>
										<option value={"digitalocean"}>Digitalocean</option>
									</select>
								</label>
							</div>

							{props.form.errors?.provider && (
								<Error>{props.form.errors.provider}</Error>
							)}

							{props.form.provider == "digitalocean" && !hasValidDoAuth() && (
								<AuthButton disabled={false} onClick={() => props.reqDigitaloceanAuth()}>
									Login with Digitalocean
								</AuthButton>
							)}

							<AuthDigitalocean visible={props.digitalocean.isAuthenticating}/>
						</div>
					</div>

					<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-b sm:border-gray-200 sm:pb-6 mt-5">
						<label
							className="block text-sm font-bold leading-5 text-primary-background sm:mt-px self-top sm:pt-2">
							Server location

							<Description>
								Due to latency and other factors we suggest picking
								something close to your actual location.
							</Description>
						</label>
						<div className="mt-1 sm:mt-0 sm:col-span-2">
							<Regions/>

							{props.form.errors?.region && (
								<Error>{props.form.errors.region}</Error>
							)}
						</div>
					</div>

					<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-b sm:border-gray-200 sm:pb-6 mt-5">
						<label htmlFor="provider"
							  className="block text-sm font-bold leading-5 text-primary-background sm:mt-px self-center">
							{serviceConfig.name} port

							<Description>
								If the default port does not work for you please change it here. The
								server will listen on it for incoming connections.
							</Description>
						</label>
						<div className="mt-1 sm:mt-0 sm:col-span-2">
							<div className={"grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-1 md:grid-cols-2"}>
								<input
									type="text"
									className="form-input mt-1 block w-full"
									value={props.form.port}
									onChange={event => props.setPort(event.target.value)}
								/>
								{props.form.errors?.port && (
									<Error>{props.form.errors.port}</Error>
								)}
							</div>
						</div>
					</div>

					{props.form.service != "wireguard" && (
						<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-b sm:border-gray-200 sm:pb-6 mt-5">
							<label htmlFor="provider"
								  className="block text-sm font-bold leading-5 text-primary-background sm:mt-px self-center">
								MTU

								<Description>
									The size of the largest protocol data unit (PDU) that
									can be communicated in a single network layer transaction.
									Leave empty for no MTU (recommended)
								</Description>
							</label>
							<div className="mt-1 sm:mt-0 sm:col-span-2">
								<div className={"grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-1 md:grid-cols-2"}>
									<input
										type="text"
										className="form-input mt-1 block w-full"
										value={props.form.mtu}
										onChange={event => props.setMtu(event.target.value)}
									/>
									{props.form.errors?.mtu && (
										<Error>{props.form.errors.mtu}</Error>
									)}
								</div>
							</div>
						</div>
					)}

					<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-b sm:border-gray-200 sm:pb-6 mt-5">
						<label htmlFor="provider"
							  className="block text-sm font-bold leading-5 text-primary-background sm:mt-px self-center">
							{serviceConfig.name} DNS servers
						</label>
						<div className="mt-1 sm:mt-0 sm:col-span-2">
							<div className={"grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-1 md:grid-cols-2"}>
								<input
									type="text"
									className="form-input mt-1 block w-full"
									value={props.form.dnsServers[0]}
									onChange={event => props.setDnsServers([event.target.value, props.form.dnsServers[1]])}
								/>
								<input
									type="text"
									className="form-input mt-1 block w-full"
									value={props.form.dnsServers[1]}
									onChange={event => props.setDnsServers([props.form.dnsServers[0], event.target.value])}
								/>
							</div>
							{props.form.errors?.dnsServers && (
								<Error>{props.form.errors.dnsServers}</Error>
							)}
						</div>
					</div>
				</div>

				<div>
					<div>
						{props.form.errors && (
							<div className="text-center mb-2">
								<Error>There are some errors in your form. Please check them out</Error>
							</div>
						)}

						{props.form.persistError && (
							<div className="text-center mb-2">
								<Error>There was an error persiting this instance. Please try again</Error>
							</div>
						)}

						<span className="block w-full rounded-md shadow-sm">
							<CreateButton onClick={validate} disabled={!canContinue()}>
								Continue with payment
							</CreateButton>
						</span>
					</div>

					<p className={"text-center"}>
						<b className="block">IMPORTANT: The cheapest droplet will be created ($5/mo)!</b>
						After installation, you can upgrade your server from the control panel to
						accommodate your needs.
					</p>
				</div>
			</Wrapper>
		</Theme>
	);
};


export const query = graphql`
	query {
		site {
			siteMetadata {
				title
				canonical
				description
			}
		}
	}
`;


const mapStateToProps = (state: iState) => {
	return {
		form        : state.form,
		digitalocean: state.digitalocean,
	};
};

const mapDispatchToProps = {
	persist,
	addError,
	setService,
	setMtu,
	setName,
	setEmail,
	setRegion,
	setProvider,
	setProtocol,
	setPort,
	setDnsServers,
	setMaskIp,
	clearErrors,
	reqDigitaloceanAuth,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Create);
