import React from 'react';

import {iState} from "@/state";
import {connect} from "react-redux";
import NewWindow from 'react-new-window';

import {iState as iStateForm} from "@/state/reducers/form";
import {iState as iStateDigitalocean} from "@/state/reducers/digitalocean";
import Loading from "@/components/loading";


interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	form: iStateForm;
	digitalocean: iStateDigitalocean;

	visible: boolean;
}

const Digitalocean: React.FunctionComponent<Props> = (props: Props) => {
	const width = 800;
	const height = 600;
	const leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
	const topPosition = (window.screen.height / 2) - ((height / 2) + 50);

	return (
		<div>
			{props.visible && (
				<div>
					<NewWindow
						title={"Authenticate with Digitalocean"}
						features={{
							status     : "no",
							resizable  : "yes",
							left       : leftPosition,
							top        : topPosition,
							screenX    : leftPosition,
							screenY    : topPosition,
							toolbar    : "no",
							menubar    : "no",
							scrollbars : "no",
							directories: "no",
							height,
							width
						}}
						url={`https://cloud.digitalocean.com/v1/oauth/authorize?client_id=${process.env.DIGITALOCEAN_CLIENT_ID}&redirect_uri=${process.env.API_URL}/oauth/digitalocean/cb&response_type=code&scope=read%20write`}/>
					<Loading
						color={"var(--primary-700)"} size={5}
						title={"Waiting for digitalocean authentication"}
					/>
				</div>
			)}
		</div>
	);
};


const mapStateToProps = (state: iState) => {
	return {
		form        : state.form,
		digitalocean: state.digitalocean,
	};
};

const mapDispatchToProps = {};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Digitalocean);
