import React from 'react';

import {connect} from 'react-redux';
// @ts-ignore
import ReactCountryFlag from "react-country-flag";

import {iRegion} from "@/state/reducers/digitalocean";

import tw, {styled} from 'twin.macro';
import {compareValues} from "@/lib/utils";
import {iState} from "@/state";
import {
	requestRegions,
	iState as iDigitaloceanState
} from "@/state/reducers/digitalocean";
import {
	iState as iStateForm, setRegion
} from "@/state/reducers/form";

interface iWrapperProps {
	providerState: iDigitaloceanState;
}

const Wrapper = styled.ul<iWrapperProps>(
	({providerState}) => [
		providerState.regions.length && tw`mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-1 md:grid-cols-2`,
		!providerState.regions.length && tw`mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-1 md:grid-cols-1`,
	]
);

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	form: iStateForm;
	digitalocean: iDigitaloceanState;
	requestRegions: typeof requestRegions;
	setRegion: typeof setRegion
}


interface RegionElProps {
	selected?: boolean;
}

const RegionEl = styled.li<RegionElProps>(
	({selected}) => [
		tw`
		col-span-1
		flex
		shadow-sm
		rounded-md
		cursor-pointer
		focus:bg-bg-background
		border-t
		border-r
		border-l
		border-b
		border-gray-200
		rounded-r-md`,
		selected ? tw`bg-bg-background` : tw`bg-white`
	]
);

const Regions: React.FunctionComponent<Props> = (props: Props) => {
	let providerState = props.digitalocean;
	if (props.form.provider == "linode") {
		// @TODO: provider
		providerState = props.digitalocean;
	}

	const sortedRegions = providerState.regions.filter(
		(r) => r.sizes.includes("s-1vcpu-1gb") && r.available
	).sort(compareValues('country'));

	return (
		<Wrapper providerState={providerState}>
			{providerState.isLoadingRegions && [1, 2, 3, 4, 5, 6].map((r) => (
				<RegionEl
					key={`loading-region-${r}`}
					selected={false}
					className={"animate-pulse"}
				>
					<div className="flex-shrink-0 flex items-center justify-center w-16 group-hover:bg-bg-background text-white text-sm leading-5 font-medium rounded-l-md border-r border-gray-200">
						<div className="rounded-full bg-gray-200 h-10 w-10"/>
					</div>
					<div className="flex-1 flex items-center justify-between truncate">
						<div className="flex-1 px-4 py-2 text-sm leading-5 truncate">
							<div className="h-4 bg-gray-200 rounded"/>
							<div className="h-4 bg-gray-200 rounded mt-3"/>
						</div>
					</div>
				</RegionEl>
			))}

			{(!providerState.isLoadingRegions && !providerState.regions.length) && (
				<div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
					<div className="flex">
						<div className="flex-shrink-0">
							<svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20" fill="currentColor">
								<path fillRule="evenodd"
									 d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
									 clipRule="evenodd"/>
							</svg>
						</div>
						<div className="ml-3">
							<p className="text-sm leading-5 text-yellow-700">
								<b>No regions found.</b>
								<span className={"block"}>
									This must be an error as I was unable to fetch any region for this
									provider. Please try a different one.
								</span>
							</p>
						</div>
					</div>
				</div>
			)}

			{!providerState.isLoadingRegions && sortedRegions.map((region: iRegion, index) => {
				return (
					<RegionEl
						key={`region-${index}`}
						selected={props.form.region === region.slug}
						className={"group"}
						onClick={() => props.setRegion(region.slug)}
					>
						<div className="flex-shrink-0 flex items-center justify-center w-16 group-hover:bg-bg-background text-white text-sm leading-5 font-medium rounded-l-md border-r border-gray-200">
							<ReactCountryFlag
								className="w-full h-full"
								countryCode={region.country}
								style={{
									fontSize  : '3rem',
									lineHeight: '3rem',
								}}
								svg
								aria-label={region.name}
							/>
						</div>
						<div className="flex-1 flex items-center justify-between truncate">
							<div className="flex-1 px-4 py-2 text-sm leading-5 truncate">
								<div className="text-gray-900 font-bold hover:text-gray-600 transition ease-in-out duration-150">
									{region.name}
								</div>
								<p className="text-gray-500">{region.slug}</p>
							</div>
						</div>
					</RegionEl>
				);
			})}
		</Wrapper>
	);
};


const mapStateToProps = (state: iState) => {
	return {
		form        : state.form,
		digitalocean: state.digitalocean,
	};
};

const mapDispatchToProps = {requestRegions, setRegion};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Regions);
